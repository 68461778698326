import styled from '@emotion/styled'
import invariant from 'invariant'
import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'
import { removeDuplicateStrings } from '../../../pure-js/libs/Common'
import { User } from '../../../pure-js/types/User'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useUser } from '../hooks/useUser'
import { getUser, updateUser } from '../libs/DBApiHandler'
import {
  getNextOnboardingChapter,
  OnboardingChapterInfoItem,
  OnboardingChapterInfoItemProps,
  OnboardingChapterInfoItems
} from '../libs/OnboardingChapterInfoItems'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'

export function OnboardingChapterItem({ chapter }: OnboardingChapterInfoItemProps) {
  const { data: user } = useUser()
  const appStateContext = useAppState()
  const item = OnboardingChapterInfoItems[chapter]
  const { videoUrl, descriptionTextKeys = [], buttonTextKey } = item

  const onClick = () => onClickCompleteChapter({ user, chapter, appStateContext })

  return (
    <Box fullWidth align="center" left right spacing={reallyBigSpacing}>
      <VideoContainer fullWidth align="center">
        <video width="100%" src={videoUrl} controls />
      </VideoContainer>
      {descriptionTextKeys.map((descriptionTextKey, key) => (
        <Box fullWidth top spacing={smallSpacing} key={key}>
          <FigmaText textKey={descriptionTextKey} />
        </Box>
      ))}
      <Box top spacing={smallSpacing}>
        <Button buttonType="secondary" textKey={buttonTextKey} onClick={() => onClick()} />
      </Box>
    </Box>
  )
}

const VideoContainer = styled(Box)`
  max-width: 700px;
`

export async function onClickCompleteChapter({
  user,
  chapter,
  appStateContext: { state, setState }
}: {
  user: User
  chapter: OnboardingChapter
  appStateContext: ReturnType<typeof useAppState>
}) {
  const intractionUser = await getUser(user.id)
  invariant(intractionUser, 'IntractionUser must be defined')
  const item: OnboardingChapterInfoItem = OnboardingChapterInfoItems[chapter]
  await updateUser({
    ...intractionUser,
    completedOnboardingSteps: removeDuplicateStrings([...(intractionUser.completedOnboardingSteps || []), chapter])
  })

  setState({ ...state, currentOnboardingChapter: getNextOnboardingChapter(chapter) })

  item.onClickCompleteSuccess?.(state)
}
