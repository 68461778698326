import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import invariant from 'invariant'
import { useEffect, useState } from 'react'
import { getArray } from '../../../pure-js/libs/Common'
import { TextKey } from '../../../pure-js/types/Antiloop'
import config from '../Config'
import { bigSpacing, reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { useQuestionaire, useQuestionaireAnswer } from '../hooks/QueryHooks'
import useAppState, { useUserId } from '../hooks/useAppState'
import { OnboardingChapterInfoItemProps, OnboardingChapterInfoItems } from '../libs/OnboardingChapterInfoItems'
import { setQuestionnaire, updateQuestionareAnswer } from '../libs/ProcessHandler'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { onClickCompleteChapter } from './OnboardingChapterItem'
import { useUser } from '../hooks/useUser'

const AssessmentQuestionTextKeys: Array<TextKey> = Object.entries(Texts)
  .filter(
    ([key]) =>
      key.startsWith('onboardingAsessmentQuestionsAssessment') &&
      key !== 'onboardingAsessmentQuestionsAssessmentProgress'
  )
  .map(([k, v]) => v as TextKey)

const LastAssessmentQuestionIndex = AssessmentQuestionTextKeys.length - 1
const NUMBER_OF_RADIOS_SMALL = 7
const NUMBER_OF_RADIOS_BIG = 8
const QUESTION_WHEN_WE_SWITCH_TO_NUMBER_OF_RADIOS_BIG = 26

export function OnboardingChapterItemAssessment({ chapter }: OnboardingChapterInfoItemProps) {
  const userId = useUserId()
  const { data: user } = useUser()
  const appStateContext = useAppState()
  const infoItem = OnboardingChapterInfoItems[chapter]
  const [index, setIndex] = useState(config.enableShorterAssessment ? LastAssessmentQuestionIndex - 2 : 0)
  const questionId = index + 1
  const { buttonTextKey } = infoItem
  const textKey = AssessmentQuestionTextKeys[index]
  const numberOfRadios =
    questionId < QUESTION_WHEN_WE_SWITCH_TO_NUMBER_OF_RADIOS_BIG ? NUMBER_OF_RADIOS_SMALL : NUMBER_OF_RADIOS_BIG

  const { data: questionaire } = useQuestionaire({ userId, context: 'preassessment' })
  const { data: quiestionaireAnswer } = useQuestionaireAnswer({
    questionaireId: questionaire?.id,
    questionId: questionId.toString()
  })

  const onClickRadioButton = async ({ answer }: { answer: number }) => {
    invariant(answer >= 1 && answer <= numberOfRadios, `Answer must be between 1 and ${numberOfRadios}`)
    invariant(questionaire, 'Questionaire must be defined')
    invariant(textKey, 'Textkey must be defined')

    await updateQuestionareAnswer({
      questionaire,
      questionId: questionId.toString(),
      textKeyName: textKey.name,
      userId,
      answer: answer.toString()
    })
  }

  const onClickLast = async () => onClickContinue({ isLast: true })
  const onClickNext = async () => onClickContinue({ isLast: false })

  const onClickContinue = async ({ isLast }: { isLast?: boolean } = {}) => {
    invariant(user, 'User must be defined')

    if (!quiestionaireAnswer?.answer) return

    if (isLast) return onClickCompleteChapter({ user, chapter, appStateContext })

    setIndex(index + 1)
  }

  useEffect(() => {
    setQuestionnaire({ userId, context: 'preassessment' })
  }, [])

  return (
    <Box fullWidth align="center" left right spacing={reallyBigSpacing}>
      <Box top spacing={smallSpacing}>
        <FigmaText styleTextKey={Texts.onboardingOnbardingPageOnboardingAssessmentHeaderText} textKey={textKey} />
      </Box>
      <Box direction="row" align="center" top spacing={bigSpacing}>
        <Box right>
          <FigmaText textKey={Texts.onboardingAsessmentQuestionsStronglyDisagreeLabel} />
        </Box>
        {getArray(numberOfRadios).map((_, index) => {
          const answer = index + 1
          const Component =
            answer.toString() === quiestionaireAnswer?.answer ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon
          return (
            <Box fullPadding spacing={smallSpacing} onClick={() => onClickRadioButton({ answer })} pointer key={index}>
              <Component color={'white' as any} />
            </Box>
          )
        })}
        <Box left>
          <FigmaText textKey={Texts.onboardingAsessmentQuestionsFullyAgreeLabel} />
        </Box>
      </Box>
      <Box top fullWidth alignText="center">
        <FigmaText
          textKey={Texts.onboardingAsessmentQuestionsAssessmentProgress}
          text={`${questionId} / ${AssessmentQuestionTextKeys.length}`}
        />
      </Box>

      <Box top direction="row" align="center">
        {index > 0 && (
          <Box fullPadding spacing={smallSpacing}>
            <Button
              buttonType="white"
              textKey={Texts.onboardingAsessmentQuestionsCtaPreviousQuestion}
              onClick={() => setIndex(index - 1)}
              disabled={index === 0}
            />
          </Box>
        )}
        <Box fullPadding spacing={smallSpacing}>
          {index < LastAssessmentQuestionIndex && (
            <Button
              buttonType="secondary"
              textKey={Texts.onboardingAsessmentQuestionsCtaNextQuestion}
              onClick={onClickNext}
            />
          )}
          {index === LastAssessmentQuestionIndex && (
            <Button buttonType="secondary" textKey={buttonTextKey} onClick={onClickLast} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
