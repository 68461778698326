import { logEvent as firebaseLogEvent } from 'firebase/analytics'
import { AnalyticsEventPropertyKeys, AnalyticsEvents } from '../../../pure-js/enums/AnalyticsEvents'
import { analytics } from '../libs/Firebase'
import { useUser } from './useUser'
import { updateUser } from '../libs/DBApiHandler'
import { User } from '../../../pure-js/types/User'
import dayjs from 'dayjs'
import config from '../Config'

export const useLogEvent = () => {
  const { data: user } = useUser()

  return async (event: AnalyticsEvents, properties?: Record<AnalyticsEventPropertyKeys, string>) => {
    if (!user) return
    logUserEvent(user, event, properties)
  }
}
export async function logUserEvent(
  user: User,
  event: AnalyticsEvents,
  properties?: Record<AnalyticsEventPropertyKeys, string>
) {
  analytics && firebaseLogEvent(analytics, event as string, properties)

  await updateUser({
    ...user,
    analyticsEvents: { ...user.analyticsEvents, [event]: { ...properties, createdAt: dayjs().format() } }
  })
}
